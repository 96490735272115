<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("signerGroup.signer_group_id") }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mr-2"
          style="width:100px !important;"
          :placeholder="$t('search')"
          @keyup.native.enter="getList"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
        <v-btn
          v-if="$store.getters.checkPermission('signer_group-create')"
          color="#6ac82d"
          x-small
          dark
          fab
          @click="newItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="ma-1"
        single-expand
        item-key="id"
        show-expand
        :expanded="expanded"
        style="border: 1px solid #aaa;"
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        @update:page="updatePage"
        :disable-pagination="true"
        disable-sort
        :footer-props="{
          itemsPerPageOptions: [50, 100, 200],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
        @update:expanded="toggleExpand"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="$store.getters.checkPermission('signer_group-update')"
            color="blue"
            small
            text
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters.checkPermission('signer_group-delete')"
            color="red"
            small
            text
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-3">
            <v-card class="my-2">
              <div>
                <v-system-bar window color="#eee">
                  <b>{{ $t("signerGroup.staff_list") }}</b>
                  <v-spacer></v-spacer>
                  <v-icon color="success" medium @click="newStaff(item)"
                    >mdi-plus</v-icon
                  >
                </v-system-bar>
              </div>
              <v-container fluid class="pa-1">
                <table class="infoTable ma-0 pa-0">
                  <tr>
                    <th>#</th>
                    <th>{{ $t("staff.staff") }}</th>
                    <th>{{ $t("department.name") }}</th>
                    <th>{{ $t("actionTypes.index") }}</th>
                    <th style="width:150px;">
                      {{ $t("actionTypes.sequence") }}
                    </th>
                    <th style="max-width: 100px !important">
                      {{ $t("documentTemplates.due_day_count") }}
                    </th>
                    <th style="max-width: 100px !important">
                      {{ $t("documentTemplates.sign_type") }}
                    </th>
                    <th style="max-width: 100px !important">
                      {{ $t("documentTemplates.registry_type") }}
                    </th>
                    <th style="width:100px;">{{ $t("actions") }}</th>
                  </tr>
                  <tr
                    v-for="(itm,
                    idx) in item.signer_group_details
                      .slice()
                      .sort((a, b) => (a.sequence < b.sequence ? -1 : 1))"
                    :key="idx"
                  >
                    <td>{{ idx + 1 }}</td>
                    <td>
                      {{
                        itm.staff && itm.staff.position["name_" + $i18n.locale]
                      }}
                    </td>
                    <td>
                      {{
                        itm.staff &&
                          itm.staff.department && itm.staff.department["name_" + $i18n.locale]
                      }}
                    </td>
                    <td>{{ itm.action_type["name_" + $i18n.locale] }}</td>
                    <td style="text-align:center;">{{ itm.sequence }}</td>
                    <td style="text-align:center;">{{ itm.due_day_count }}</td>
                    <td style="text-align:center;">
                      {{ itm.sign_type ? "e-imzo" : "AD" }}
                    </td>
                    <td style="text-align:center;">
                      {{ itm.is_registry ? "Registrli" : "Registrsiz" }}
                    </td>
                    <td class style="max-width: 40px;">
                      <v-btn
                        color="blue"
                        class="my-1"
                        x-small
                        text
                        @click="editStaff(itm)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        color="red"
                        class="my-1"
                        x-small
                        text
                        @click="deleteStaff(itm)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </v-container>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      @keydown.esc="dialog = false"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ dialogHeaderText }}</span>
          <v-spacer></v-spacer>

          <v-btn color="red" outlined x-small fab class @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="save" ref="dialogForm">
            <v-row>
              <v-col cols="12">
                <label for>{{ $t("name_uz_latin") }}</label>
                <v-text-field
                  v-model="form.name_uz_latin"
                  :rules="[v => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <label for>{{ $t("name_uz_cyril") }}</label>
                <v-text-field
                  v-model="form.name_uz_cyril"
                  :rules="[v => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <label for>{{ $t("name_ru") }}</label>
                <v-text-field
                  v-model="form.name_ru"
                  :rules="[v => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="StaffDialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ SignerStaffDetaildialogHeaderText }}</span>
          <v-spacer></v-spacer>

          <v-btn
            color="red"
            outlined
            x-small
            fab
            class
            @click="StaffDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="StaffSave" ref="staffDialogform">
            <v-row>
              <v-col cols="12">
                <label for>{{ $t("staff.index") }}</label>
                <v-autocomplete
                  clearable
                  v-model="StaffForm.staff_id"
                  :items="staffList"
                  item-text="staffInfo"
                  item-value="id"
                  :rules="[v => !!v || $t('input.required')]"
                  hide-details
                  dense
                  outlined
                  full-width
                  class="my-1"
                >
                  <template v-slot:selection="{ item }">
                    <v-row class="ma-0 pa-0" style="font-size:12px;">
                      <v-col cols="12" class="ma-0 pa-0">
                        <b>{{
                          item.department
                            ? item.department.code + " " + item.department.text
                            : ""
                        }}</b>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0">
                        <b>{{ item.position ? item.position.text : "" }}</b>
                      </v-col>
                      <!-- <v-col cols="12" class="ma-0 pa-0">
                        {{$t('employee.range')}}:
                        {{item.range ? item.range.code : ''}} /
                        {{$t('staff.rate_count')}}:
                        {{item.rate_count}}
                      </v-col>-->
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-row
                      style="border-bottom:1px solid #ccc; font-size:14px;max-width:700px;"
                      class="ma-0 pa-0"
                    >
                      <v-col cols="12" class="ma-0 pa-0">
                        <b>{{
                          item.department
                            ? item.department.code + " " + item.department.text
                            : ""
                        }}</b>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0">
                        <b>{{ item.position ? item.position.text : "" }}</b>
                      </v-col>
                      <!-- <v-col cols="12" class="ma-0 pa-0">
                        {{$t('employee.range')}}:
                        {{item.range ? item.range.code : ''}} /
                        {{$t('staff.rate_count')}}:
                        {{item.rate_count}}
                      </v-col>-->
                    </v-row>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <label for>{{ $t("actionTypes.index") }}</label>
                <v-autocomplete
                  clearable
                  v-model="StaffForm.action_type_id"
                  :items="actionTypes"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <label for>{{ $t("actionTypes.sequence") }}</label>
                <v-text-field
                  v-model="StaffForm.sequence"
                  :rules="[v => !!v || $t('input.required')]"
                  type="number"
                  min="1"
                  max="20"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <label for>{{ $t("documentTemplates.due_day_count") }}</label>
                <v-text-field
                  v-model="StaffForm.due_day_count"
                  :rules="[v => !!v || $t('input.required')]"
                  type="number"
                  min="1"
                  max="20"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <label for>{{ $t("documentTemplates.sign_type") }}</label>
                <v-select
                  v-model="StaffForm.sign_type"
                  clearable
                  :items="[
                    { value: 1, text: 'e-imzo' },
                    { value: 0, text: 'AD' }
                  ]"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="4">
                <label for>{{ $t("documentTemplates.registry_type") }}</label>
                <v-select
                  v-model="StaffForm.is_registry"
                  clearable
                  :items="[
                    { value: 1, text: 'Reisterli' },
                    { value: 0, text: 'Reistersiz' }
                  ]"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveStaff">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios").default;
import Swal from "sweetalert2";

export default {
  data: () => ({
    dateRules: [
      v =>
        !v ||
        /^\d{4}[\-\-](0?[1-9]|1[012])[\-\-](0?[1-9]|[12][0-9]|3[01])$/.test(
          v
        ) ||
        "Date must be valid(YYYY-DD-MM)"
    ],
    expanded: [],
    loading: false,
    dataTableValue: [],
    search: "",
    dialog: false,
    signerStaffDetailDialog: false,
    editMode: null,
    staffList: [],
    items: [],
    details: [],
    form: {},
    dialogHeaderText: "",
    SignerStaffDetaildialogHeaderText: "",
    StaffForm: {},
    StaffDialog: false,
    actionTypes: [],
    signerGroups: [],
    page: 1,
    from: 0,
    server_items_length: -1,
    dataTableOptions: { page: 1, itemsPerPage: 50 }
  }),
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
    headers() {
      return [
        { text: "", value: "data-table-expand", width: 30 },
        { text: "#", value: "id", align: "center", width: 30 },
        {
          text: this.$t("name_uz_latin"),
          value: "name_uz_latin"
        },
        {
          text: this.$t("name_uz_cyril"),
          value: "name_uz_cyril"
        },
        {
          text: this.$t("name_ru"),
          value: "name_ru"
        },
        {
          text: this.$t("actions"),
          value: "actions",
          width: 30,
          align: "center"
        }
      ].filter(
        v =>
          v.value != "actions" ||
          this.$store.getters.checkPermission("signer_group-update") ||
          this.$store.getters.checkPermission("signer_group-delete")
      );
    }
  },
  methods: {
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    saveStaff() {
      if (this.$refs.staffDialogform.validate())
        axios
          .post(
            this.$store.state.backend_url + "api/signerGroupDetail/update",
            this.StaffForm
          )
          .then(res => {
            this.getList();
            this.StaffDialog = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: this.$t("create_update_operation")
            });
          })
          .catch(err => {
            console.log(err);
          });
    },

    toggleExpand($event) {},
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/signers-groups", {
          pagination: this.dataTableOptions,
          search: this.search,
          language: this.$i18n.locale
        })
        .then(response => {
          this.items = response.data.signer_groups.data;
          this.from = response.data.signer_groups.from;
          this.server_items_length = response.data.signer_groups.total;
          this.actionTypes = response.data.action_types.map(v => {
            return { text: v.name, value: v.id };
          });
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    getRef() {
      axios
        .get(
          this.$store.state.backend_url +
            "api/signers-groups/get-ref/" +
            this.$i18n.locale
        )
        .then(response => {

          this.staffList = response.data.staffs.map(v => {
            v.staffInfo = "";
            if (v.department) {
              v.staffInfo += v.department.code;
              v.staffInfo += " ";
              v.staffInfo += v.department.text;
            }
            if (v.range) v.staffInfo += v.range.code;
            v.staffInfo += " ";
            if (v.position) v.staffInfo += v.position.text;
            return v;
          });

          console.log(this.staffList)

          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    deleteStaff(item) {
      const index = this.items.indexOf(item);
      Swal.fire({
        title: this.$t("swal_title"),
        text: this.$t("swal_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("swal_delete")
      }).then(result => {
        if (result.value) {
          axios
            .delete(
              this.$store.state.backend_url +
                "api/signers_group_detail/delete/" +
                item.id
            )
            .then(res => {
              this.getList(this.page, this.itemsPerPage);
              this.StaffDialog = false;
              Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
            })
            .catch(err => {
              Swal.fire({
                icon: "error",
                title: this.$t("swal_error_title"),
                text: this.$t("swal_error_text")
                //footer: "<a href>Why do I have this issue?</a>"
              });
              console.log(err);
            });
        }
      });
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      Swal.fire({
        title: this.$t("swal_title"),
        text: this.$t("swal_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("swal_delete")
      }).then(result => {
        if (result.value) {
          axios
            .delete(
              this.$store.state.backend_url +
                "api/signers-groups/delete/" +
                item.id
            )
            .then(res => {
              this.getList(this.page, this.itemsPerPage);
              this.dialog = false;
              Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
            })
            .catch(err => {
              Swal.fire({
                icon: "error",
                title: this.$t("swal_error_title"),
                text: this.$t("swal_error_text")
                //footer: "<a href>Why do I have this issue?</a>"
              });
              console.log(err);
            });
        }
      });
    },
    saveStaff() {
      if (this.$refs.staffDialogform.validate())
        axios
          .post(
            this.$store.state.backend_url + "api/signerGroupDetail/update",
            this.StaffForm
          )
          .then(res => {
            this.getList();
            this.StaffDialog = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: this.$t("create_update_operation")
            });
          })
          .catch(err => {
            console.log(err);
          });
    },
    save() {
      if (this.$refs.dialogForm.validate())
        axios
          .post(
            this.$store.state.backend_url + "api/signers-groups/update",
            this.form
          )
          .then(res => {
            this.getList();
            this.dialog = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: this.$t("create_update_operation")
            });
          })
          .catch(err => {
            console.log(err);
          });
    },
    updatePage($event) {
      this.getList();
    },
    newStaff(item) {
      this.StaffForm = {
        id: Date.now(),
        signer_group_id: item.id,
        staff_id: "",
        action_type_id: "",
        sequence: "",
        due_day_count: "",
        is_registry: "",
        sign_type: ""
      };
      this.StaffDialog = true;
      this.editMode = false;
      if (this.$refs.staffDialogform) this.$refs.staffDialogform.reset();
    },

    newItem() {
      if (this.$store.getters.checkPermission("signer_group-create")) {
        this.dialogHeaderText = this.$t("signerGroup.new_signer_group");
        this.form = {
          id: Date.now(),
          name_uz_latin: "",
          name_uz_cyril: "",
          name_ru: ""
        };
        this.dialog = true;
        this.editMode = false;
        if (this.$refs.dialogForm) this.$refs.dialogForm.reset();
      }
    },
    editItem(item) {
      if (this.$store.getters.checkPermission("signer_group-update")) {
        this.dialogHeaderText = this.$t("signerGroup.edit_signer_group");
        this.form = Object.assign({}, item);
        this.dialog = true;
        this.editMode = true;
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation();
      }
    },

    editStaff(item) {
      this.dialogHeaderText = this.$t("edit staff");
      this.StaffForm = Object.assign({}, item);
      this.StaffDialog = true;
      this.editMode = true;
      if (this.$refs.staffDialogform)
        this.$refs.staffDialogform.resetValidation();
    }
  },

  mounted() {
    this.getList();
    this.getRef();
  }
};
</script>

<style scoped></style>
